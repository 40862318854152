import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import KTButton from './KTButton';

const styles = (theme) => ({
  buttonWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
});

class LoadingButton extends Component {
  render() {
    const { size, style, loading, onClick, children, classes } = this.props;

    return (
      <div className={classes.buttonWrapper}>
        <KTButton
          size={size}
          style={{...style, filter: "brightness(0.8)"}}
          loading={loading}
          onClick={onClick}
        >
          {children}
        </KTButton>
        {loading && (
          <CircularProgress size={16} color="secondary" className={classes.progress} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(LoadingButton);
