export const OperationState = Object.freeze({
  LOADING: 0,
  CANCELLED: 1,
  SUCCESS: 2,
  ERROR: 3,
});

/**
 * 
 * @param {boolean} isLoading Stateful loading value
 * @param {function} setIsLoading Stateful loading value setter
 * @param {function} setCurrentOperationState Stateful operationState value setter
 * @returns {(operationState: number) => void} The function that handles the stateful values
 */
export function getOperationStateHandlerFunc(isLoading, setIsLoading, setCurrentOperationState) {
  /**
   * @param {number} operationState - OperationState enum value.
   */
  return (operationState) => {
    setCurrentOperationState(operationState)
    switch(operationState) {
      case OperationState.LOADING:
        setIsLoading(true)
        break;
      case OperationState.SUCCESS:
        setIsLoading(false)
        break;
      case OperationState.ERROR:
        setIsLoading(false)
        break;
      case OperationState.CANCELLED:
        if (isLoading) {
          break;
        }
        setIsLoading(false)
        break;
      default:
        setIsLoading(false)
        break;
    }
  }
}