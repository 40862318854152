import React,{Fragment} from 'react';
import {Grid,Typography ,Paper} from '@material-ui/core';
import QRCode from 'qrcode.react';

import {KTInputField,KTSelectField,KTDateField,KTTimeField,KTInputAutoCompleteField,KTFileUpload,KTRadioField,KTTableView, KTButton,KTMultipleSelectField,KTBarcode,KTCheckBox,KTIcon} from '../../KT';
import {MagbizGeneral} from '../../utils/Magbiz'

const containerWidth = 600

class DocDefaultContent extends React.Component {

  shouldComponentUpdate(nextProps, nextState){
    if(this.props !== nextProps){
      return true
    }
    return false
  }

  _onFieldChange = (event) => {
    this.props.onFieldChange(event)
  }

  _onCheckBoxChange = (name, value, cvalue, ncvalue) => {
    const vlist = MagbizGeneral.value(value)+""
    const list = (vlist ? vlist.split("|") : [])
    const indexOfValue = list.indexOf(cvalue)
    if(ncvalue && indexOfValue === -1){
      list.push(cvalue)
    }else if(!ncvalue && indexOfValue !== -1){
      list.splice(indexOfValue, 1);
    }
    const eventr = {target:{name:name, value:list.join('|')}}
    this.props.onFieldChange(eventr)
  }

  _onDateTimeChange = (dateObj) => {
    this.props.onDateTimeChange(dateObj)
  }

  _onFieldSelect = (name, selectId) => {
    this.props.onFieldSelect(name, selectId)
  }

  _onFileUploadImageChange = (name, filepathurl, filepath) => {
    this.props.onFileUploadImageChange(name, filepathurl, filepath)
  }

  _onFire = (name, fields) => {
    this.props.onFire(name, fields)
  }

  render(){
    //console.log("Render DocDefaultContent")
    const {groupObj, fields, mode, width, contentType} = this.props

    if(groupObj.hideCondition && groupObj.hideCondition(fields, mode)) return null

    let titleStyle = {backgroundColor:"#f2f2f2", margin:0}
    let paperStyle = {marginLeft:10, marginRight:10, marginBottom:20}
    let containerStyle = {padding:20, paddingTop:15}
    let fieldItemStyle = {padding:5}
    let fieldDirection = "column"
    let labelMinHeight = 21.5
    let labelFontWeight = 600
    let gTextAlign = "left"
    if(groupObj.fieldStyle === "document"){
      fieldDirection = (groupObj.fieldDirection ? groupObj.fieldDirection : "row")
      labelMinHeight = 24
      labelFontWeight = 600
      containerStyle.padding = 10
      delete containerStyle["paddingTop"]
      if(contentType === "header"){
        paperStyle = {...paperStyle, boxShadow:"none", marginLeft:0, marginRight:0, marginBottom:0, borderRadius:0, border:"1px solid #f2f2f2" }
      }else{
        paperStyle = {...paperStyle, boxShadow:"none", marginLeft:3, marginRight:3, marginBottom:10, borderRadius:0, border:"1px solid #f2f2f2" }
      }
      if(groupObj.compact) fieldItemStyle.padding = 0
      if(groupObj.fieldDirection === "column") containerStyle.paddingTop = 5
    }else if(groupObj.fieldStyle === "signature" || groupObj.fieldStyle === "signature_date"){
      labelFontWeight = 600
      containerStyle.padding = 0
      containerStyle.paddingTop = 0
      gTextAlign = "center"
      fieldItemStyle.padding = 0
      fieldItemStyle.paddingLeft = 20
      fieldItemStyle.paddingRight = 20
      if(groupObj.fieldStyle === "signature_date"){
        fieldDirection = "row"
      }
    }else{
      if(groupObj.compact) {
        containerStyle.padding = 10
        containerStyle.paddingTop = 5
      }
    }

    if(typeof groupObj.marginTop !== "undefined"){paperStyle.marginTop = groupObj.marginTop;}
    if(typeof groupObj.marginBottom !== "undefined"){paperStyle.marginBottom = groupObj.marginBottom;}
    if(typeof groupObj.marginLR !== "undefined"){paperStyle.marginLeft = groupObj.marginLR; paperStyle.marginRight = groupObj.marginLR}
    if(groupObj.noBordorRadius) {paperStyle.boxShadow = "none"; paperStyle.borderRadius = 0; paperStyle.border ="1px solid #f2f2f2"}
    if(groupObj.noBordor || groupObj.noBorder) {paperStyle.boxShadow = "none"; paperStyle.border = "none"}
    if(groupObj.noPadding) containerStyle.padding = 0
    if(groupObj.minHeight) containerStyle.minHeight = groupObj.minHeight
    if(typeof groupObj.containerPaddingTop !== "undefined") containerStyle.paddingTop = groupObj.containerPaddingTop
    if(typeof groupObj.containerPaddingLR !== "undefined") containerStyle.paddingLeft = groupObj.containerPaddingLR
    if(typeof groupObj.containerPaddingLR !== "undefined") containerStyle.paddingRight = groupObj.containerPaddingLR
    if(typeof groupObj.itemPaddingLR !== "undefined") fieldItemStyle.paddingLeft = groupObj.itemPaddingLR
    if(typeof groupObj.itemPaddingLR !== "undefined") fieldItemStyle.paddingRight = groupObj.itemPaddingLR
    if(groupObj.paddingL){paperStyle.paddingLeft = groupObj.paddingL;}
    if(groupObj.paddingR){paperStyle.paddingRight = groupObj.paddingR;}
    if(groupObj.minHeight){paperStyle.minHeight = groupObj.minHeight;}
    if(groupObj.fieldDirection) fieldDirection = groupObj.fieldDirection
    if(groupObj.dotDocument) {
      labelFontWeight = 500
      if(paperStyle.border !== "none"){
        paperStyle.border = "1px solid #000"
      }
      if(groupObj.title){
        titleStyle.backgroundColor = "#f2f2f2"
        titleStyle.borderBottom ="1px solid #000"
      }
    }

    return (
      <Grid item xs={(!Boolean(groupObj.span) || width < containerWidth ? 12 : groupObj.span )} style={groupObj.style} >
        <Paper style={paperStyle} >
          <Typography variant="subtitle1" gutterBottom style={titleStyle}>
            {groupObj.title && <div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10}}>{groupObj.title}</div>}
          </Typography>
          <Grid container style={containerStyle}>
            {groupObj.fields && Object.values(groupObj.fields).map((item, index) => {

              let value = item.value
              let value2 = ""
              if(fields){
                if(item.nameValue && fields[item.nameValue]){
                  value = fields[item.nameValue].value
                }else if(fields[item.name]){
                  value = fields[item.name].value
                }
                const preTitle = (item.preTitle ? item.preTitle : "title_")
                if(item.type === "image" && item.haveTitle && fields[preTitle+item.name] && fields[preTitle+item.name].value){
                  value2 = fields[preTitle+item.name].value
                }
              }

              if(item.formatValue) value = item.formatValue(value)
              if(item.formatCustom) value = item.formatCustom(fields)
              if(typeof value === "undefined") value = ""
              //if(value && item.display && mode === "view") value = item.display   // Need to fixed bug
              if(item.hidden) return (<input key={index} type="hidden" name={item.name} value={value} />)
              if(item.type === "title") return null

              let labelxs = (fieldDirection === "column" ? 12 : 12 - (item.xs ? item.xs : 6) )
              if(labelxs === 0) labelxs = 12

              const createMarkup = (text) => {
                return {__html: text};
              }

              let textAlign = gTextAlign
              if(item.align) textAlign = item.align

              if(item.hideCondition && item.hideCondition(fields, mode)) return null

              let dotField = false
              if(groupObj.dotDocument && !item.fieldOnTable) {
                dotField = true
              }

              let inputFieldMarginTop = 0
              let inputFieldMarginBottom = 0
              if(groupObj.fieldStyle === "document" && item.xs === 12){
                inputFieldMarginTop = -4
                inputFieldMarginBottom = 4
              }

              return (
                <Grid key={index} item xs={(item.spanM && width < containerWidth ? item.spanM : (item.span ? item.span : 12))} style={{...fieldItemStyle, maxWidth:item.maxWidth, marginTop:item.marginTop}}  >
                  <Grid container direction="row">

                    {item.label &&
                    <Grid item xs={labelxs} style={{minHeight:labelMinHeight, textAlign:(item.lalign ? item.lalign : gTextAlign)}}>
                      <Typography variant="caption" style={{marginBottom:(fieldDirection === "column" ? 2 : 0 ), marginTop:2, marginRight:(item.lalign === "right" ? 2 : 0),fontWeight:labelFontWeight, color:(item.required && MagbizGeneral.isEmpty(value) ? "#2E94CC" : "black")}}>
                        {item.label}
                      </Typography>
                    </Grid>}

                    <Grid item xs={(fieldDirection === "column" ? 12 : (item.xs ? item.xs - (item.plxs ? item.plxs : 0 ) : 6) )} style={{minHeight:(typeof item.minHeight !== "undefined" ? item.minHeight : 24 ), marginTop:inputFieldMarginTop, marginBottom:inputFieldMarginBottom}}>

                        {(item.type === "int" || item.type.startsWith("decimal") || item.type === "text" || item.type === "password")  &&
                        <KTInputField mytheme={this.props.mytheme} name={item.name} type={item.type}  defaultValue={value} placeholder={item.placeholder} multiline={item.multiline}
                          align={textAlign} uppercase={item.uppercase} maxLength={item.maxLength}
                          fieldOnTable={item.fieldOnTable} dotField={dotField} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={this._onFieldChange} />}

                        {item.type === "image" &&
                        <Fragment>
                          <div style={{width:(item.width ? item.width : "100%"), height:item.height}}>
                            <KTFileUpload name={item.name} folder_name="tmp" nohint uid="randomtime" contain={item.contain} containDefault={item.containDefault} fileTypes={item.fileTypes}
                              src={value} defaultSrc={item.defaultSrc} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                              onImageChanged={this._onFileUploadImageChange} />
                          </div>
                          {item.haveTitle &&
                          <KTInputField name={(item.preTitle ? item.preTitle : "title_")+item.name} type={"text"} defaultValue={value2} placeholder={item.placeholder} multiline={item.multiline}
                            disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))} align="center" nolineOnDisabled={true}
                            onChange={this._onFieldChange} />}
                        </Fragment>}

                        {item.type === "select" &&
                        <KTSelectField mytheme={this.props.mytheme} name={item.name} placeholder={item.placeholder} align={textAlign}
                          fieldOnTable={item.fieldOnTable} dotField={dotField} icon={item.icon} defaultValue={value} blankNotAllowed={!Boolean(item.allowBlank)} async={Boolean(item.async)} ajax={item.ajax} isMulti={item.isMulti}
                          suggestions={item.list} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={(e) => this._onFieldChange(e)} small no_outline />}

                        {item.type === "multipleselect" &&
                        <KTMultipleSelectField mytheme={this.props.mytheme} name={item.name} placeholder={item.placeholder} align={textAlign}
                          fieldOnTable={item.fieldOnTable} dotField={dotField} icon={item.icon} defaultValue={value} blankNotAllowed={!Boolean(item.allowBlank)} async={Boolean(item.async)} ajax={item.ajax}
                          suggestions={item.list} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={(e) => this._onFieldChange(e)} small no_outline />}

                        {item.type === "autocomplete" &&
                        <KTInputAutoCompleteField mytheme={this.props.mytheme} name={item.name} placeholder={item.placeholder} align={textAlign}
                          fieldOnTable={item.fieldOnTable} dotField={dotField} defaultValue={value} suggestions={item.list} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={(e) => this._onFieldChange(e)} onSelect={(name, selectId) => this._onFieldSelect(name, selectId)} small no_outline />}

                        {item.type === "date" &&
                        <KTDateField mytheme={this.props.mytheme} type="date" name={item.name} placeholder={item.placeholder}
                          fieldOnTable={item.fieldOnTable} dotField={dotField} icon="calendar" small defaultValue={value} align={textAlign}
                          disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={(e) => this._onDateTimeChange(e)} />}

                        {item.type === "time" &&
                        <KTTimeField mytheme={this.props.mytheme} type="time" name={item.name} placeholder={item.placeholder}
                          fieldOnTable={item.fieldOnTable} dotField={dotField} icon="calendar" small defaultValue={value} align={textAlign}
                          disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={(e) => this._onDateTimeChange(e)} />}

                        {item.type === "radio" &&
                        <KTRadioField name={item.name} list={item.list}
                          small defaultValue={value} dotField={dotField}
                          disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                          onChange={(e) => this._onFieldChange(e)} />}

                        {item.type === "checkboxlist" &&
                        <div style={{marginBottom:0}}>
                          {item.list && item.list.map((citem, index) => {
                            return (
                              <KTCheckBox key={index} label={citem.label} disabled={item.disabled || mode === "view" || (item.lockCondition && item.lockCondition(fields, mode))}
                                size={12} defaultValue={MagbizGeneral.searchInListString(citem.value, value+"", "|")}
                                color={item.color} onChange={(ncvalue) => this._onCheckBoxChange(item.name, value, citem.value, ncvalue )} />
                            )
                          })}
                        </div>}

                        {item.type === "html" &&
                        <div style={item.style} dangerouslySetInnerHTML={createMarkup(value)}/>}

                        {item.type === "htmllabel" &&
                        <div style={item.style} dangerouslySetInnerHTML={createMarkup(item.ltext)}/>}

                        {item.type === "qrcode" &&
                        <QRCode value={value} size={item.size} />}

                        {item.type === "barcode" &&
                        <KTBarcode value={value} title={item.title} fontSize={item.fontSize} height={item.height} hidetitle={item.hidetitle} style={item.style} />}

                        {item.type === "table" &&
                        <KTTableView name={item.name} value={value} style={{...item.style, width:(item.width ? item.width : "100%"), height:item.height}} />}

                        {item.type === "button" &&
                        <KTButton size={item.size ? item.size : "small"} onClick={(e) => item.onClick(value,fields)} style={{...item.style, width:(item.width ? item.width : "100%"), height:item.height}}   >
                          {item.text}
                        </KTButton>}

                        {item.type === "iconbutton" &&
                        <div style={{cursor:"pointer", marginTop:5, textAlign:"center"}} onClick={(e) => this._onFire(item.name, fields)}>
                          <KTIcon size={item.size} color={item.color} vicon={item.icon} vicontype={item.icontype} />
                        </div>}

                    </Grid>

                    {item.postlabel && fieldDirection === "row" &&
                    <Grid item xs={item.plxs} style={{minHeight:labelMinHeight, textAlign:(item.postlabelalign ? item.postlabelalign : "left"), paddingRight:(item.postlabelPR ? item.postlabelPR : 0 ), marginTop:inputFieldMarginTop, marginBottom:inputFieldMarginBottom}}>
                      <Typography variant="caption" style={{marginBottom:(fieldDirection === "column" ? 2 : 0 ), marginTop:1}}>
                        {item.postlabel}
                      </Typography>
                    </Grid>}

                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

export default DocDefaultContent
